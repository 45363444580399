@import "../../../../../../assets/css/bootstrap/variables";
@import '../../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.market-modal {
	&.skills-market .modal-body {
		background-image: linear-gradient(to bottom right, $info, white);
	}

	&.services-market .modal-body {
		background-image: linear-gradient(to bottom right, $warning, white);
	}

	.modal-body {
		.game-card, .demo-card {
			display: inline-block;
			min-height: 270px;
			padding-bottom: 20px;

			&:hover {
				z-index: 2;
			}
		}

		.filters {
			@include media-breakpoint-down(sm) {
				select, input {
					width: 100%;
				}

				> div {
					&, .form-group {
						width: 100%;
					}
				}
			}
		}
	}

	.available-for-market {
		> div {
			border-radius: 20px;
			background: #46536D;
		}

		img {
			height: 1.9rem !important;
		}
	}

	.demo-card {
		width: 16rem;
	}
}
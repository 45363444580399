@import "../../../../../../../assets/css/bootstrap/variables";

.value-proposition-confirm-modal {
	.game-card {
		width: MIN(90%, 500px);
	}

	.value-proposition-card {
		width: 100%;
	}

}


@import "assets/css/bootstrap/variables";

body.desktop .hired-service{
	.gender-icon{
		left: 35px;
		top: 55%;
		width: 17px;
	}
}

body.mobile .hired-service{
	.gender-icon{
		left: 40px;
		top: 60%;
		width: 19px;
	}
}

.hired-service {
	position: relative;

	.skill-area {
		display: block;
		max-width: 5.3rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.skill-name {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.gender-icon {
		border-radius: 50%;
		box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
		position: absolute;
	}

	.founder-icon{
		position: absolute;
		left: 10px;
		top: 60%;

		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;

		background: $danger;
		border-radius: 50%;
	}
}

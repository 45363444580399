@import "../assets/css/bootstrap/variables";
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../node_modules/bootstrap/scss/variables';

#i18n-overlay {
  z-index: 999999999999;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba($dark, 0.15);
  animation: i18n-overlay 2s infinite;

  h1 {
    opacity: 0;
    position: absolute;
    top: 50%;
    animation: i18n-overlay-animation 12s linear infinite;
  }

  h1:nth-child(2) {
    animation-delay: 4s;
  }

  h1:nth-child(3) {
    animation-delay: 8s;
  }

  @keyframes i18n-overlay-animation {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }

    2% {
      opacity: 1;
      transform: translateY(0);
    }

    30% {
      opacity: 1;
      transform: translateY(0);
    }

    32% {
      opacity: 0;
      transform: translateY(50px);
    }

    100% {
      opacity: 0;
      transform: translateY(50px);
    }
  }
}


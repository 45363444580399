@import "../../../../../../../assets/css/bootstrap/variables";

.value-proposition-conception-modal {
	&.hidden {
		opacity: 0;
		transition: opacity 0.3s;
	}

	.value-propositions-list {
		border-right: 2px solid rgba(128, 128, 128, 0.76);

		.game-card, .vp-demo-placeholder {
			display: inline-block;
			padding-bottom: 10px;
			height: 100%;
			min-height: 80px !important;
			width: 200px !important;

			.value-proposition-card {
				height: 100%;
				width: 100%;
			}
		}
	}

	.selected-value-proposition {
		.game-card {
		}

		.value-proposition-card {
			height: 370px;
			width: 100% !important;

			.value-content {
				overflow: hidden;
			}

			.content-scrollable {
				max-height: 100%;
			}
		}
	}


	.vp-btn.pulsating {
		animation: pulseBtnVpModalConceptionModal 2s infinite;

		@keyframes pulseBtnVpModalConceptionModal {
			0% {
				box-shadow: 0 0 0 0 rgba($primary, 0.8);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($primary, 0);
			}
		}
	}

	.vp-demo-placeholder {
		font-size: 90%;
	}

}


.startup-profile-modal {
  .startup-card {
    width: 33rem !important;

    .startup-card-content-container {
      overflow: hidden;
    }

    .content-scrollable {
      max-height: 100%;
    }
  }

  .value-proposition-card {
    width: 16rem;
  }

  .founder-card {
    height: 225px;
    width: 16rem !important;

    .box-content {
      overflow: hidden;
    }

    .content-scrollable {
      max-height: 100%;
    }
  }
}
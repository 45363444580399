@import "../../../../../../../../assets/css/bootstrap/variables";
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '~bootstrap/scss/_variables';

.show-question-modal {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	background: rgba($primary, 0.95);
	overflow: auto;

	height: 100vh;
	width: 100vw;

	@include media-breakpoint-up(md) {
		.question-container {
			width: MIN(100%, 600px) !important;
		}

		.container-custom {
			min-width: 900px;
		}

		.content-container {
			height: 90%;
		}

		.card-container {
			animation-name: fadeInLeft;
		}

		.result-container {
			animation-name: fadeInRight;
		}
	}

	@include media-breakpoint-down(sm) {
		.question-container {
			width: 100% !important;

		}

		.game-card {
			max-width: 95%;
		}

		.question-card {
			padding-bottom: 4rem !important;
		}

		.card-container {
			animation-name: fadeInUp;
		}

		.result-container {
			animation-name: fadeInDown;
		}
	}

	.box-answer-result img {
		width: 150px;
	}


	.game-card {
		width: 100%;
		min-height: 440px;
		max-height: 600px;
		height: 100%;
		padding-bottom: 2rem;

		.value-content {
			height: 1%;
			overflow: hidden;
		}

		.content-scrollable {
			max-height: 100%;
		}
	}
}
#control-panel {
  .game-info-input {
    label {
      margin-right: 0.75rem;
      margin-bottom: 0 !important;
    }

    select {
      width: 70px;
    }

  }

  .game-info {
    .form-group {
      margin-bottom: 0 !important;
    }
  }

  .state-button {
    &:not(.current-state-button):not(:hover) {
      opacity: 0.4;
    }

    &.current-state-button {
      cursor: default;
      pointer-events: none;
    }
  }
}
@import "../../../assets/css/bootstrap/variables";

$board-card-preview-margin-x: 0.5rem;

.smgx-card {
  position: relative;
  margin-right: $board-card-preview-margin-x;
  margin-left: $board-card-preview-margin-x;

  .box {
    background: $primary;
    position: relative;
    margin-bottom: 0.625rem;

    border-style: solid;
    border-width: 0.313rem;
    border-radius: 1.125rem;

    &.clickable {
      &.hover-blur:hover {
        .box-content {
          opacity: 0.1;
        }

        .show-on-hover {
          content: '';
          position: absolute;
          display: block !important;
          z-index: 9;
          opacity: 1;
          color: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .show-on-hover {
        display: none;
        opacity: 0;
      }

      .box-content {
        transition: opacity 0.15s ease-in-out;
      }
    }
  }

  .box-head, .box-head-right {
    font-weight: 800;
    letter-spacing: 0.13rem;
    font-size: 0.9rem;
  }

  .box-head {
    margin: -1px -1px 0.625rem -1px;
    padding: 0.313rem 1.25rem 0.313rem 0.938rem;
    border-radius: 0.625rem 0 1.125rem 0;
    float: left;
  }

  .box-head-right {
    margin: -1px -1px 0.625rem -1px;
    padding: 0.313rem 1.25rem 0.313rem 0.938rem;
    border-radius: 0 0.625rem 0 1.125rem;
    float: right;
  }

  .box-head-right-clickable {
    margin: -1px -1px 0.625rem -1px;
    padding: 0.313rem 1.25rem 0.313rem 0.938rem;
    border-radius: 0 0.625rem 0 0.625rem;
    float: right;

    font-weight: 600;
    font-size: 0.9rem;
  }

  .box-content {
    clear: both;
    margin: 0.625rem;

    p {
      font-size: 1rem;
      font-weight: 300;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: 0.063rem;
      clear: both;
    }

    h4 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0.313rem;
      clear: both;
    }

    table:not(.not-styled) {
      margin: 0.938rem 0;
      width: 100%;
      border-collapse: collapse;
      font-size: 0.938rem;
      font-weight: 400;

      td {
        padding: 0.188rem;

        &:nth-last-child(1) {
          text-align: right;
        }
      }

      tr:nth-child(odd) {
        background-color: rgba(238, 238, 238, 0.1);
      }
    }
  }

  .box-footer {
    margin: 0 -1px -1px -1px;
    padding: 0.3rem 0 0.1rem 0;
    border-radius: 0 0 0.5rem 0.5rem;
    text-align: center;

    button {
      letter-spacing: 0.12rem;
      margin: 0 !important;
      font-weight: 700;

      &:hover {
        color: $light;
      }
    }
  }

  p, h1, h2, h3, h4, h5, h6 {
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    &:last-child {
      margin-bottom: initial !important;
    }
  }

  .skill-face {
    width: 2.75rem;
  }

  .skill-area {
    border-radius: 1.125rem;
    background-color: rgba(#b3b3b3, 0.38);
    padding: 0.1rem 0.3rem;
    margin-right: 0.625rem;
    width: fit-content;
    margin-bottom: 0.20rem;

    &, p {
      font-variant: small-caps;
      text-transform: lowercase;
      font-weight: 600;
      font-size: 0.65rem;
      letter-spacing: 0.063rem;
    }
  }

  .power-rating {
    display: flex;
    padding-right: 0.2rem;

    img {
      width: 0.7rem;
      display: inline-block;
    }
  }

  .skill-name {
    font-size: 0.85rem !important;
    color: white;
  }
}
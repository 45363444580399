@import "../../../../../../../assets/css/bootstrap/variables";

body.desktop .ranking-player-team {
	.content-scrollable {
		max-height: 470px;
	}
}

body.mobile .ranking-player-team {

}

.ranking-player-team {
	background: $dark;
	border-style: solid;
	border-width: 0.313rem;
	border-radius: 1.125rem;

	max-width: 500px;
	min-width: 50%;

	.box-head {
		font-weight: 800;
		letter-spacing: 0.13rem;
		font-size: 1rem;
		float: left;

		margin: -1px -1px 0.625rem -1px;
		padding: 0.313rem 1.25rem 0.313rem 0.938rem;
		border-radius: 0.625rem 0 1.125rem 0;
	}
}
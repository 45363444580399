@import "../../../../assets/css/bootstrap/variables";

.session-not-started-yet {
  min-height: 600px;
  background-image: linear-gradient(to right top, #2f3f5b, #2c3a54, #2a354c, #273145, #242c3e);

  > div {
    max-width: 1400px;
    position: relative;
  }

  .text-content {
    position: relative;
    z-index: 99;
  }

  .text-content-container {
    display: flex;
    align-items: center;

    @media (max-width: 1100px) {
      justify-content: center;
    }
  }


  .logo-header {
    width: 275px;
    @media (min-width: 1100px) {
      display: none;
    }
  }

  .bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;

    @media (max-width: 1099px) {
      align-items: center;

      img.bg-img {
        width: 700px;
        opacity: 0.1;
      }

      img.logo {
        display: none;
      }
    }

    @media (min-width: 1100px) {
      padding-right: 3rem;
      align-items: flex-end;

      img.bg-img {
        width: 600px;
        opacity: 1;
      }

      img.logo {
        display: block;
        width: 15%;
        min-width: 200px;
        margin-top: 1rem;
      }
    }
  }
}
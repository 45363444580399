.text-decoration-underline {
	text-decoration: underline;
}

.no-pointer-events {
	pointer-events: none !important;
}

.cursor-pointer {
	cursor: pointer;
}

.radius-4 {
	border-radius: 4px !important;
}

.gutters-1 {
	& > * {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}
}

.clickable, .clickable * {
	cursor: pointer;
}

.bottom {
	bottom: 0;
}

.left {
	left: 0;
}

.right {
	right: 0;
}

.top {
	top: 0;
}

.medium {
	font-size: 90%;
}


.fit-content {
	white-space: nowrap;
	width: 1%;
}

.status-dot {
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	display: inline;
}

.no-wrap {
	white-space: nowrap;
}

.transparent {
	opacity: 0;
}

.border-circle {
	border-radius: 50% !important;
}

.opacity-95 {
	opacity: .95 !important;
}

.overflow-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.not-selectable {
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// QHD resolution
.container-custom {
	max-width: 2560px;
}

body.mobile .w-mobile-100{
	width: 100%;
}
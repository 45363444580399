@import "../../../../assets/css/bootstrap/variables";

.lobby-table-players {
	.badge {
		font-size: 100%;
		width: 20px;
	}

	td:last-child > span {
		content: '';
		display: inline-block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}


	.current-player {
		font-weight: bold;
		text-decoration: underline;
	}

	.local-players {
		font-size: 90%;
	}

	.player-status {
		.player-online {
			background: #7AC943;
		}

		.player-offline {
			background: #FF1D25;
		}
	}
}


@import "../../../../assets/css/bootstrap/variables";

.pitch-option {
	box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
	transition: all 0.15s ease-in-out;
	border-color: $danger;

	&.selectable{
		.box-content {
			cursor: pointer;
		}

		&:hover {
			transform: scale(1.05);
			transform-origin: 50% 0;
		}
	}




	.box-skill-head, .box-footer {
		background: $primary;
	}

	.box-skill-head {
		border: 1px solid $primary;
	}

	.box-footer .line {
		border-bottom-color: $light;
	}

	&.show-loading .loading-overlay {
		opacity: 1 !important;
		z-index: 3 !important;
	}

	&.show-confirm .confirm-overlay {
		opacity: 1 !important;
		z-index: 2 !important;
	}

	&.show-loading, &.show-confirm {
		.confirm-overlay, .loading-overlay {
			padding-bottom: 10px;
		}

		.card-description, .card-summary {
			opacity: 0 !important;
		}
	}


	.skill-face {
		width: 3.75rem;
		left: 0;
		top: 0;
		margin: 0.5rem 0.625rem 0 0.625rem;
	}

	.box-skill-head {
		width: calc(100% + 2px) !important;
		margin: -1px -1px 0 -1px;
		padding: 0.625rem 0.625rem 0.625rem 5.3rem;
		border-radius: 0.625rem 0.625rem 0 0;
		font-weight: 600;
		font-size: 1rem;
		line-height: 125%;
		color: $light;

		min-height: 3.75rem;
		max-height: 3.75rem;
		position: absolute;
		z-index: 3;

		> div {
			max-height: 3.75rem;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			word-break: break-word;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:hover {
			//transition: max-height .5s linear;
			overflow: hidden;
			white-space: initial;
			min-height: 4.75rem;
			box-shadow: 0 4px 4px rgba($dark, 0.4);

			max-height: 100vh !important;
			height: initial !important;

			> div {
				max-height: 100vh !important;
				display: initial !important;
			}
		}
	}

	.skill-content {
		padding-top: 0.313rem;
		line-height: 1.4;
		margin-top: 4.2rem !important;

		p {
			font-size: 0.95rem !important;
		}
	}


	.loading-overlay, .confirm-overlay {
		border-radius: 4px;
		display: flex;
		z-index: -1;
		opacity: 0;
		transition: all 0.15s ease;
	}

	.hired-amount {
		transition: all 0.15s ease-in-out;

		width: 30px;
		margin-top: -3px;
		margin-right: -3px;
		border-bottom-left-radius: 50%;
		border-top-right-radius: 50%;
	}
}
#confirm-theme-modal {
	.game-card {
		width: 50%;
		max-width: 350px;
		//min-height: 250px;
	}

	.startup-card, .value-proposition-card, .founder-card {
		width: 100% !important;
		height: 100%;
	}

	.icon{
		width: 50px;
	}
}

body.desktop #confirm-theme-modal{
	.game-card {
		width: 50%;
	}
}

body.mobile #confirm-theme-modal{
	.game-card {
		width: 100%;
	}
}
@import "../../../../../../assets/css/bootstrap/variables";

.smgx-accounting-steps-details-table {
	td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(7) {
		text-align: center;
	}

	td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6),
	th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6) {
		text-align: center;
	}

	td:nth-child(7), th:nth-child(7) {
		text-align: end !important;
	}

	tbody {
		tr:not(:last-child) {
			td:nth-child(1) {
				color: $warning;
			}

			td:nth-child(4), td:nth-child(5) {
				color: $danger;
			}

			td:nth-child(6) {
				color: $success;
			}
		}
	}

	.signature {
		font-family: 'Waterfall', cursive !important;
		font-size: 1.25rem;
	}
}

.account-details-modal .phase-summary {
	min-width: 520px;
}
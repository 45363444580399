@import "../../../assets/css/bootstrap/variables";

// excess height to improve interactive area / accessibility
$height: 30px;
$thumb-height: 13px;
$track-height: 3px;

// colours
$upper-color: #cfcfcf;
$lower-color: $primary;
$thumb-color: $dark;
$thumb-hover-color: $info;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

#adjust-volume-modal {
	.volume-control {
		transition: all 0.2s ease-in-out;

		button {
			width: 25px;
		}

		p {
			font-weight: 600;
			width: 40px;
			text-align: right;
			color: $dark;
		}

		&.muted {
			input, p {
				opacity: 0.5;
			}
		}

		input {
			display: block;
			appearance: none;
			width: 100%;
			margin: 0;
			height: $height;
			overflow: hidden;
			cursor: pointer;

			&:focus {
				outline: none;
			}

			// Webkit
			&::-webkit-slider-runnable-track {
				width: 100%;
				height: $height;
				background: $lower-background;
			}

			@function webkit-slider-thumb-shadow($i: 1) {
				$val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
				@if $i == 1 {
					@for $k from 2 through 1000 {
						$val: #{$val}, webkit-slider-thumb-shadow($k);
					}
				}
				@return $val;
			}

			&::-webkit-slider-thumb {
				position: relative;
				appearance: none;
				height: $thumb-height;
				width: $thumb-height;
				background: $thumb-color;
				border-radius: 100%;
				border: 0;
				top: 50%;
				margin-top: (-$thumb-height/2);
				box-shadow: webkit-slider-thumb-shadow();
				transition: all 0.15s ease-in-out;
			}

			// Firefox
			&::-moz-range-track,
			&::-moz-range-progress {
				width: 100%;
				height: $height;
				background: $upper-background;
			}

			&::-moz-range-progress {
				background: $lower-background;
			}

			&::-moz-range-thumb {
				appearance: none;
				margin: 0;
				height: $thumb-height;
				width: $thumb-height;
				background: $thumb-color;
				border-radius: 100%;
				border: 0;
				transition: all 0.15s ease-in-out;
			}

			// Internet Exploder
			&::-ms-track {
				width: 100%;
				height: $height;
				border: 0;
				// color needed to hide track marks
				color: transparent;
				background: transparent;
			}

			&::-ms-fill-lower {
				background: $lower-background;
			}

			&::-ms-fill-upper {
				background: $upper-background;
			}

			&::-ms-thumb {
				appearance: none;
				height: $thumb-height;
				width: $thumb-height;
				background: $thumb-color;
				border-radius: 100%;
				border: 0;
				transition: all 0.15s ease-in-out;
				top: 0;
				margin: 0;
				box-shadow: none;
			}

			&:hover {
				&::-webkit-slider-thumb {
					background-color: $thumb-hover-color;
				}

				&::-moz-range-thumb {
					background-color: $thumb-hover-color;
				}

				&::-ms-thumb {
					background-color: $thumb-hover-color;
				}
			}
		}
	}

	.form-check {
		input[type=checkbox] {
			transform: scale(1.5);
			padding: 10px;
		}

		label {
			margin-left: 5px;
		}
	}


}


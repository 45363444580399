body.desktop .pitch-challenge .game-card {
	max-height: 600px;
	max-width: 500px;
	min-width: 450px;

	.content-scrollable {
		max-height: 450px;
	}

	.box {
		min-height: 20rem !important;
	}
}


body.mobile .pitch-challenge .game-card {
	max-width: 500px;
	width: 100%;
}


.pitch-challenge {

}

@import "../../../../assets/css/bootstrap/variables";


body.mobile .journey-map-summary-card {
	.navigate-buttons {
		gap: 10px;

		button {
			&:focus, &:active {
				color: white !important;
				background-color: transparent !important;
			}
		}
	}

	.step-result {
		gap: 20px;

		.result-col {
			gap: 10px;

			img {
				height: 5rem;
			}

			svg {
				font-size: 5rem;;
			}
		}

		.step-summary-item {
			img, svg {
				margin-right: .5rem;
				width: 1.2rem;
			}
		}

		p {
			margin-bottom: .1rem !important;
		}
	}

	#btn-show-game-map {
		width: 100%;
		max-width: 450px;
	}
}

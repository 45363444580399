.unpredictability-team-row {
  height: 410px;

  .card-container {
    min-height: 285px;
  }

  .btn-lg {
    transition: background-color 0.25s ease-in-out;
  }

  .btns-container {
    height: 46px;
  }

  .unpredictability-card {
    max-width: 800px;

    .game-card {
      .box {
        min-width: 300px;
        height: 210px;
      }

      .content-scrollable {
        max-height: 140px;
      }
    }

    .content-scrollable-consequences {
      max-height: 270px;
      width: 400px;
    }
  }

}
@import "../../assets/css/bootstrap/variables";

.founder-card {
  transition: all 0.15s ease-in-out;

  &.selectable {
    &:hover {
      transform: scale(1.05);
    }

    &.show-confirm {
      .confirm-overlay {
        opacity: 1 !important;
        z-index: 2 !important;
      }

      .content-scrollable p {
        opacity: 0 !important;
      }

      .skill-area {
        opacity: 0 !important;
      }
    }

    .box-content {
      cursor: pointer;
    }
  }

  .skill-face {
    left: 0;
    top: 0;
    width: 3.75rem;
    margin: 0.625rem 0 0 0.625rem;
    z-index: 4 !important;
  }

  .box-skill-head {
    width: calc(100% + 2px) !important;
    margin: -1px -1px 0 -1px;
    // height: 2.5rem;
    padding: 0.625rem 0.625rem 0.625rem 4.85rem;
    border-radius: 0.625rem 0.625rem 0 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 125%;
    color: $light;
    // min-height: 3.75rem;
    border: 1px solid $primary;
    z-index: 3 !important;

    min-height: 3.75rem;
    max-height: 3.75rem;
    position: absolute;
    display: block;

    > div {
      max-height: 3.75rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      transition: max-height .5s linear;
      white-space: normal;
      min-height: 4.60rem;
      max-height: 100vh;
      box-shadow: 0 4px 4px rgba($dark, 0.4);

      > div {
        max-height: 100vh;
        display: initial !important;
      }
    }
  }

  .skill-content {
    padding: 15px 5px 0 5px !important;
    margin: 3.6rem 0 0 0 !important;

    p {
      color: black;
      clear: both;
    }
  }

  .skill-area {
    border-radius: 1.125rem;
    background-color: #b3b3b3;
    padding: 0.188rem 0.625rem;
    margin-right: 0.625rem;
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.063rem;
    float: left;
    text-transform: uppercase !important;

    + div {
      font-weight: 600;
    }
  }

  .confirm-overlay {
    border-radius: 4px;
    background: white;
    z-index: -1;
    opacity: 0;
    transition: all 0.15s ease;

    &.show {
      opacity: 1;
      z-index: 2;
    }
  }
}


@import "../../../assets/css/bootstrap/variables";
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';


.pitch-modal {
	.modal-body {
		background-image: linear-gradient(to bottom right, $info, white);
	}

	.modal-body {
		.game-card {
			display: inline-block;
			min-height: 200px;
			padding-bottom: 20px;

			&:hover {
				z-index: 2;
			}
		}

		@include media-breakpoint-down(sm) {
			.selected-items-container, .selected-item-badge {
				width: 95%;
			}
		}

		.selected-item-badge {
			font-size: 75%;
		}

		.selected-items-container {
			min-height: 28px;
		}

	}
}
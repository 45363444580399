.game-events.desktop {
	background: #575F70;
	border-radius: 30px;
	min-height: 200px;
	z-index: 3;
	position: relative;

	& > div {
		min-width: calc(100% + 8px);
		min-height: calc(100% + 10px);
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23394D64' stroke-width='15' stroke-dasharray='20' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
		border-radius: 30px;
		margin: 0 -4px 0 -4px;
		position: absolute;
		top: -4px;
	}

	.game-card {
		height: 100%;

		.box {
			height: 100%;
		}
	}

	.simplebar-content {
		height: 100%;
	}
}
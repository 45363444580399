body.desktop .unpredictability-challenge .unpredictability-card {
	width: 450px;

	.game-card {
		.box {
			min-width: 300px;
			height: 210px;
		}

		.content-scrollable {
			max-height: 140px;

			.simplebar-content {

			}
		}
	}

	.content-scrollable-consequences {
		max-height: 270px;
	}
}

body.mobile .unpredictability-challenge .unpredictability-card {
	max-width: 500px;
	width: 100%;

	.game-card {
		width: 90%;

		.content-scrollable {
			max-height: 140px;
		}
	}

	.game-card .box {
		width: 100%;
		height: 210px;
	}
}


.unpredictability-challenge .unpredictability-card {

}



$duration: 5s;
$text-color: rgba(255, 255, 255, 0.3);
$bgr-color: #333;

.team-step-in-podium {
  min-height: 600px;

  &.fourth-pos-container {
    margin-left: 7rem;
  }

  p {
    margin-bottom: 0;
  }

  .totals {
    height: 145px;

    :first-child {
      animation-delay: 0.25s !important;
    }

    :nth-child(2) {
      animation-delay: 0.75s !important;
    }

    :nth-child(3) {
      animation-delay: 1s !important;
    }

    :nth-child(4) {
      animation-delay: 1.25s !important;
    }

  }


  &.first-pos .podium-step {
    height: 300px;
  }

  &.second-pos .podium-step {
    height: 220px;
  }

  &.third-pos .podium-step {
    height: 140px;
  }

  &.fourth-pos .podium-step {
    height: 45px;
  }

  .podium-step-placeholder {
    background: rgba(255, 255, 255, 0.16);
    border: 3px solid gray;
  }

  .podium-step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 140px;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 4px;

    h4 {
      margin-top: .5rem;
      margin-bottom: 0;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
    }
  }
}
#player-form-modal {
  .badge {
    font-size: 80% !important;
    text-transform: uppercase;
  }

  .player-item {
    button {
      min-width: 60px;
    }
  }

  .pulsating {
    animation: pulsePlayerForm 1.5s infinite;

    @keyframes pulsePlayerForm {
      0% {
        box-shadow: 0 0 0 0 rgba(white, 0.7);
        color: #2a354c;
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        color: rgba(42, 53, 76, 0.7);
      }

      100% {
        color: #2a354c;
        box-shadow: 0 0 0 0 rgba(white, 0);
      }
    }
  }
}
#demo-video-player {
	background: #242C3E;

	// HIDE TITLE BAR!!
	.youtube-container {
		overflow: hidden;
		width: 100%;
		/* Keep it the right aspect-ratio */
		aspect-ratio: 16/9;
		/* No clicking/hover effects */
		pointer-events: none;
	}

	.youtube-container iframe {
		/* Extend it beyond the viewport... */
		width: 300%;
		height: 100%;
		/* ...and bring it back again */
		margin-left: calc(-100% + 0px);
	}


	.video-control {
		button {
			width: 35px;
			height: 35px;
			background: rgb(226, 226, 226);
			border: 1px solid rgb(226, 226, 226);
			border-radius: 50%;
			opacity: 0.9;
			cursor: pointer;

			&.btn-close {
				width: 26px;
				height: 26px;
			}

			&.btn-skip {
				border-radius: 4px;
				width: auto;
				font-size: 85%;
				color: white;
				border: none;
				background: none;
				transition: all .1s linear;

				&:hover {
					background: rgb(226, 226, 226);
					color: black;
				}
			}
		}

		input[type=range] {
			max-width: 75px;
		}

		.video-control-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		.controls-container {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.23));
		}
	}

}


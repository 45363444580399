@import "../../../../../assets/css/bootstrap/variables";

.skills-summary-card {
	transition: background-color .20s linear;

	&.showing-skill {
		background: $dark;
		cursor: pointer;
	}

	&.pulsating{
		animation: pulseMarket 2s infinite;

		@keyframes pulseMarket {
			0% {
				box-shadow: 0 0 0 0 rgba($warning, 1);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($warning, 0);
			}
		}
	}

	.box-content {
		height: 300px;
		margin: 0 !important;
	}

	.hired-service {
		cursor: pointer;
		min-height: 59px;

		&:hover {
			background: rgba(238, 238, 238, 0.1);
		}
	}

	.diversity-bonus-container {
		position: absolute;
		top: -4px;
		right: -4px;
		padding: 6px 3px 3px;
		border-radius: 0 1.125rem 0 1.125rem;
		background: #EEEEEE;
		color: $dark;
	}

}
@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../node_modules/bootstrap/scss/variables';

body.mobile .tabs-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	height: 58px;
	border-top: 2px solid gray;

	display: grid;
	grid-auto-columns: minmax(0, 1fr);
	grid-auto-flow: column;

	@include media-breakpoint-up(md) {
		height: 38px;

		.svg-inline--fa {
			font-size: 24px !important;
		}
	}

	button {
		height: 100%;
		//flex: 1 1 auto !important;
		border-radius: 0;
	}
}
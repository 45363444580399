.service-show {
  .skill-title {
    line-height: 1.2;
  }

  .skill-area {
    font-size: 0.9rem;
  }

  .description {
    line-height: 1.4;
    font-size: 0.9rem;
  }

  .btn-danger {
    z-index: 99;
  }
}
.conception-modal-card-placeholder {
  height: 195px;
  width: 16rem;
  opacity: 0.75;
  border-style: dashed !important;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  .bg {
    opacity: 0.3;
  }
}
@import "../../../../../../../assets/css/bootstrap/variables";

.unpredictability-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: linear-gradient(to bottom right, $dark, $primary);
  //background: rgba($primary, 0.95);
  overflow: auto;

  height: 100vh;
  width: 100vw;

  > div {
    min-width: 850px;
  }

  .team-option {
    cursor: pointer;

    &:hover:not(.font-weight-bold) {
      color: lightgray !important;
    }
  }


  .btn.bg-transparent {
    &:hover {
      color: darken(lightgray, 10%) !important;
    }
  }

  .box-answer-result img {
    width: 150px;
  }

  .btn-reveal-answer {
    height: 100px;
    font-size: 1.5rem;
    font-weight: bold;
  }

}
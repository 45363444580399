@import "../../../../../../../assets/css/bootstrap/variables";

.game-event-card {
	max-width: 210px;
	height: 160px !important;

	&.full {
		.right-card-content {
			max-height: initial !important;
		}
	}

	.right-card-content {
		height: 100%;
		max-height: 120px;
	}

	.game-event-card-content {
		padding: 0rem 0.625rem 0.4rem 0.625rem;
		line-height: 1.3;
		font-size: 0.95rem;

		h5 {
			color: $dark;
			font-size: 1.125rem;
			font-weight: 700;
		}

		p {
			max-height: 100%;
		}
	}

	.simplebar-content {
		height: 100%;
	}

	.box-head {
		margin-bottom: 0 !important;
	}

}


#lobby #lobby-header {
  min-height: 100px;
  background: transparent;

  .session-name {
    width: 270px;
  }

  img {
    width: 70px;
    max-height: 70px;
    max-width: 70px;
    object-fit: contain;
  }

  button {
    border-radius: 4px !important;
  }
}

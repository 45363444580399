@import "../../../assets/css/bootstrap/variables";

body.desktop #animated-step-change {
	.container-custom {
		min-width: 900px;
	}
}

body.mobile #animated-step-change {
	.map {
		filter: blur(2px) brightness(95%);
		height: 100vh !important;
		width: unset !important;

		transition: opacity 0.2s ease-in-out;
	}

	.step-info {
		max-width: 100vw;
	}
}


#animated-step-change {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 5;

	overflow: auto;

	height: 100vh;
	width: 100vw;

	&.esg {
		background: darken($success, 30%);
	}

	&.smgx {
		background: $dark;
	}

	.content-container {
		height: 90%;
	}

	.step-info {
		background-color: rgba(0, 0, 0, 0.32);
		backdrop-filter: blur(15px);
	}
}
@import "../../assets/css/bootstrap/variables";
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../node_modules/bootstrap/scss/variables';

.question-card {
	&.correct {
		.answer-incorrect {
			opacity: 0.35;
		}

		.outcome-container {
			background: lighten($success, 3%);
		}
	}

	&.incorrect {
		.answer-correct {
			opacity: 0.35;
		}

		.outcome-container {
			background: $danger;
		}
	}

	&.answerable tr {
		cursor: pointer;

		&:hover {
			background: rgba($info, 0.2);
		}
	}


	tr {
		border-left: 5px solid;
		border-left-color: transparent;

		&:not(:last-child) {
			border-bottom: 1px solid lightgray;
		}

		&.selected {
			border-left-color: $danger !important;
			background: rgba($danger, 0.2);
		}


		tr.correct-answer {
			font-weight: bold;
			background: red;
		}
	}

	td {
		padding: 0.5rem 0.5rem 0.5rem 0.5rem;

		&:nth-child(2) {
			width: 1%;
		}
	}

	.answer-progress {
		height: 40px;
		border-top: 1px solid $danger;
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		font-weight: 600;

		img {
			margin-right: 0.5rem;
			width: 1.5rem;
			height: 1.5rem;
		}

		@media (max-width: 1550px) {
			font-size: 0.8rem;
			padding-bottom: 0.25rem;
			padding-top: 0.25rem;

			.answer-progress-result {
				flex-direction: column;
			}

			img {
				margin-right: 0;
				width: 1.1rem;
				height: 1.1rem;
			}
		}
	}

	.question-header-container div:first-child {
		display: block;
		max-width: 85%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		position: absolute;
		z-index: 3;
		max-height: 35px;

		&:hover {
			transition: max-height .5s linear;
			white-space: normal;
			max-height: 100vh;
			box-shadow: 0 4px 4px rgba($dark, 0.4);
		}
	}

	.fa-x {
		font-size: 1.5rem;
	}

	.simplebar-content {
		padding: 0.625rem 0.625rem 0 0.625rem !important;
	}

	.box-footer {
		padding: 0.8rem 0 0.5rem 0 !important;

		button {
			font-size: 1.25rem;
		}
	}

	.answer-result {
		position: relative;
		border-radius: 30px;
		background-color: #575F70;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%235F7084' stroke-width='12' stroke-dasharray='20' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
		height: calc(50% - 0.25rem);

		&:not(.active) {
			img {
				opacity: 0.30;
			}
		}

		img {
			width: 50%;
		}
	}

	button {
		white-space: nowrap;
		font-weight: bold;
		height: 100px;
		font-size: 1.7rem;
	}

	&.correct {
		.answer-incorrect {
			opacity: 0.35;
		}
	}

	&.incorrect {
		.answer-correct {
			opacity: 0.35;
		}
	}


	// CHECKBOX
	/* Customize the label (the container) */
	.container {
		display: block;
		position: relative;
		margin-bottom: 25px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		border: 1px solid $primary;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #B3B3B3;
	}

	/* disabled style */
	.container input:disabled ~ .checkmark {
		background-color: #949494 !important;
		border-color: #727272 !important;
		cursor: not-allowed !important;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		background-color: $primary;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
}


@import "../../../../../../assets/css/bootstrap/variables";

$tr-bg-color: #757B88;

.accounting-details-container {
	transition: all .2s ease-in-out;

	.phases .nav-link {
		font-weight: bold;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		padding-bottom: 0.3rem !important;
		background: transparent;
		transition: background-color .2s ease-in-out;

		@media screen and (min-width: 716px) {
			margin: 0 1px -1px;
			border-bottom-width: 0;
		}

		@media screen and (max-width: 715px) {
			width: 100% !important;
			margin-bottom: 5px !important;
			border-radius: 4px !important;
		}

		&.disabled {
			color: gray !important;
			border-color: gray !important;
		}


		&.active {
			&.border-danger {
				background-color: $danger !important;
			}

			&.border-warning {
				background-color: $warning !important;
			}

			&.border-info {
				background-color: $info !important;
			}

			&.border-light {
				background-color: $light !important;
				color: $dark !important;
			}

			&.border-success {
				background-color: $success !important;
			}
		}
	}

	.table-responsive {
		thead tr, tbody tr:last-child {
			background: $tr-bg-color !important;
			color: $dark !important;
			font-weight: bold;
		}

		tbody {
			color: white;

			td {
				padding: 0.5rem !important;
				vertical-align: middle;
			}
		}

		tr > th:first-child, tr > td:first-child {
			position: sticky;
			left: 0;
			box-shadow: 0 0 1px #000 inset;
		}


		tr:not(:last-child) {
			&:nth-child(odd) td {
				background: #30394C;
			}

			&:nth-child(even) td {
				background: #262F43;
			}
		}

		tr:last-child td:first-child, th:first-child {
			background: $tr-bg-color;
		}
	}

	.btn.pulsating {
		animation: pulseBtnAccountingDetails 2s infinite;

		@keyframes pulseBtnAccountingDetails {
			0% {
				box-shadow: 0 0 0 0 rgba(white, 0.7);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba(white, 0);
			}
		}
	}
}
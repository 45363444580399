.conception-founder-modal {
	.game-card {
		display: inline-block;
		min-height: 250px;
		padding-bottom: 20px;
		height: 100%;
	}

	.founder-card, .founder-card-placeholder {
		width: 300px !important;
		height: 100%;
	}
}
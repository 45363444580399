@import "../../../../assets/css/bootstrap/variables";

#tournament-conception-modal .conception-custom-theme {
	transition: 0.2s ease-in-out;

	&:hover {
		scale: 1.05;
	}

	> div {
		border: 5px solid $danger;
		border-radius: 1.125rem;
		background: rgba($danger, 0.2);
		margin-left: 7.5px;
		margin-right: 7.5px;
	}
}

body.mobile #tournament-conception-modal .conception-custom-theme {
	.icon {
		padding-top: 0.5rem;
		width: 20%;
	}
}

body.desktop #tournament-conception-modal .conception-custom-theme {
	.icon {
		width: 25%;
	}
}
body.desktop .team-step-in-podium-player {
	&.fourth-pos-container {
		margin-left: 2rem;
	}

	p {
		margin-bottom: 0;
	}

	.team-number {
		font-size: 100%;
	}

	.totals {
		height: 120px;
		font-size: 80%;

		:first-child {
			animation-delay: 0.25s !important;
		}

		:nth-child(2) {
			animation-delay: 0.75s !important;
		}

		:nth-child(3) {
			animation-delay: 1s !important;
		}
	}


	&.first-pos .podium-step {
		height: 250px;
	}

	&.second-pos .podium-step {
		height: 175px;
	}

	&.third-pos .podium-step {
		height: 75px;

	}

	&.fourth-pos .podium-step {
		height: 30px;
	}

	.podium-step-placeholder {
		background: #858585FF;
		border: 3px solid gray;
	}

	.podium-step {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		width: 70px;
		margin-left: 1rem;
		margin-right: 1rem;
		border-radius: 4px;

		h4 {
			margin-top: .5rem;
			margin-bottom: 0;
			text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
		}
	}
}
@import "../../../../assets/css/bootstrap/variables";

#lobby .user-list {
	.box {
		background: linear-gradient(to bottom left, $dark, $primary) !important;
		background: -webkit-linear-gradient(right, $dark, $primary) !important;
		background: -moz-linear-gradient(right, $dark, $primary) !important;
		background: linear-gradient(to left, $dark, $primary) !important;
	}

	.simplebar {
		width: 325px;
	}

	.simplebar-scrollbar:before {
		background: $light !important;
	}

	.game-master-info-container h3:last-child {
		color: white;
	}

	.lobby-table-players {
		color: white;

		thead tr {
			background-color: transparent !important;

			th:nth-child(3) {
				padding-left: 0 !important;
			}
		}

		td {
			padding-top: 0.3rem;
			padding-bottom: 0.3rem;
			vertical-align: middle;
		}
	}
}
@import "../../css/bootstrap/_variables";

$swal2-box-shadow: black;
$swal2-toast-box-shadow: 0 0 .625em black;

$swal2-timer-progress-bar-background: $primary;

$swal2-toast-title-margin: 0 0 0 1rem;
$swal2-toast-html-container-margin: 0 0 0 1rem;


$swal2-success: $success;
$swal2-success-border: rgba($swal2-success, .3);
$swal2-error: $danger;
$swal2-warning: $warning;
$swal2-info: $info;
$swal2-question: $primary;


$swal2-confirm-button-border-radius: .25rem;
$swal2-confirm-button-background-color: $primary;
$swal2-confirm-button-color: white;


$swal2-deny-button-border-radius: .25rem;
$swal2-deny-button-background-color: $danger;
$swal2-deny-button-color: white;
$swal2-deny-button-focus-box-shadow: 0 0 0 3px rgba($danger, .5) !default;


$swal2-cancel-button-border-radius: .25rem;
$swal2-cancel-button-background-color: white;
$swal2-cancel-button-color: #212529;
$swal2-cancel-button-focus-box-shadow: 0 0 0 3px rgba($swal2-cancel-button-background-color, .5) !default;

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	z-index: 99;
	//overflow: auto;
	background: rgba(255, 255, 255, 1);

	transition: transform 0.5s ease-in-out;

	&.hidden {
		transform: translateX(-100%);
		//opacity: 0;
	}

	&.visible {
		transform: translateX(0);
		//opacity: 100%;
	}

	.sidebar-header {
		min-height: 55px;
	}
}
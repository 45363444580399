@import "../../../../assets/css/bootstrap/variables";

.messages {
  .box {
    background: linear-gradient(to bottom left, $dark, $primary) !important;
    background: -webkit-linear-gradient(right, $dark, $primary) !important;
    background: -moz-linear-gradient(right, $dark, $primary) !important;
    background: linear-gradient(to left, $dark, $primary) !important;
  }

  .simplebar {
    width: 325px;
  }

  .simplebar-scrollbar:before {
    background: $light !important;
  }
}
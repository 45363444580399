@import "../../../../../assets/css/bootstrap/variables";
@import '../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../../node_modules/bootstrap/scss/variables';


body.mobile .account-summary-card {
	.bonus-container {
		.bonus-icon {
			margin: 0 5px;
			width: 65px;
		}

		p {
			max-width: 225px;
		}
	}

	.hired-cards-container {
		column-gap: 5px;
	}

	.hired-service {
		position: relative;
		width: calc(33% - 5px);

		@include media-breakpoint-down(sm) {
			width: calc(50% - 5px);;
		}

		@media screen and (max-width: 450px) {
			width: 100% !important;
		}

		&, * {
			cursor: default !important;
		}

		.skill-face {
			width: 2.75rem;
		}

		.skill-area {
			display: block;
			max-width: 6rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #212529;

			border-radius: 1.125rem;
			background-color: rgba(179, 179, 179, 0.38);
			padding: 0.1rem 0.3rem;
			margin-right: 0.625rem;
			width: -moz-fit-content;
			width: fit-content;

			font-feature-settings: "smcp";
			font-variant: small-caps;
			text-transform: lowercase;
			font-weight: 600;
			font-size: 0.8rem;
			letter-spacing: 0.063rem;
		}

		.skill-name {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			font-weight: 300;
			font-size: 0.85rem !important;
			margin-bottom: 0 !important;
		}

		.gender-icon {
			border-radius: 50%;
			box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
			position: absolute;
			left: 35px;
			top: 55%;
			width: 17px;
		}
	}

	.btn.pulsating {
		animation: pulseBtnAccounting 2s infinite;

		@keyframes pulseBtnAccounting {
			0% {
				box-shadow: 0 0 0 0 rgba($info, 0.7);
			}

			70% {
				box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($info, 0);
			}
		}
	}

	.table-responsive {
		@include media-breakpoint-down(sm) {
			overflow-x: scroll !important;
		}
	}
}
body.mobile .sidebar-option {
	&.disabled {
		opacity: 1 !important;
		cursor: not-allowed !important;
		color: rgba(108, 108, 108, 0.61);
	}

	&.selected .notification-badge {
		background: white !important;
	}

	.notification-badge {
		pointer-events: none;
		top: 2px;
		left: calc(50% + 20px);
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}
}
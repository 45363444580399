@import "../../../assets/css/bootstrap/variables";


#modal-journey-map {
	.board-wrapper {
		min-height: 200px;

		object {
			animation-delay: .5s;
		}

		&.rotate{
			height: calc(100vw - 2rem);
			width: 75vh;
			transform: rotate(90deg) translateY(-100%);
			transform-origin:top left;
		}
	}

	#journey-board {
		transition: opacity .4s linear;
	}
}
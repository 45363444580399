@import "../../../../../../assets/css/bootstrap/variables";
@import '../../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../node_modules/bootstrap/scss/variables';

body.mobile .question-challenge {
	.game-card {
		height: 100%;
		width: 100%;
	}

	.loading-indicator {
		min-height: 200px;
	}

	.question-tournament-buttons {
		button {
			//width: 100%;
			//font-size: 100%!important;
			//padding-top: 10px!important;
			//padding-bottom: 10px!important;
		}

		margin-bottom: 1rem;
	}
}

body.desktop .question-challenge {
	.game-card {
		height: 100%;
		width: 100%;
		max-width: MAX(460px, 80%);
		max-height: 530px;

		.content-scrollable {
			max-height: 390px;
		}
	}

	&.revealed .game-card .content-scrollable{
		max-height: 438px;
	}

	&.demo-question .game-card .content-scrollable {
		max-height: 380px !important;
	}

	&.tournament-question .game-card {
		.content-scrollable {
			max-height: 390px !important;
		}
	}

	&.tournament-question-revealed .game-card {
		max-height: 480px !important;

		.content-scrollable {
			max-height: 385px !important;
		}
	}

	.game-card .question-explanation .content-scrollable {
		//max-height: 440px !important;
		max-height: 420px !important;
	}
}


.question-challenge {
}


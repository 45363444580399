$team-bgs: (
		'../../../../assets/img/board/team_cards/lobby_box_red.svg',
		'../../../../assets/img/board/team_cards/lobby_box_orange.svg',
		'../../../../assets/img/board/team_cards/lobby_box_blue.svg',
		'../../../../assets/img/board/team_cards/lobby_box_green.svg',
);

#lobby .team-card-container {
	width: 300px;
	height: 290px;
	border-radius: 18px;
	position: relative;

	@for $index from 0 to length($team-bgs) {
		$index: $index+1;

		&.team-card-#{$index} {
			background-image: url(unquote(nth($team-bgs, $index)));
		}
	}

	&.not-joinable {
		opacity: 0.45;
	}

	.startup-info {
		background: rgba(255, 255, 255, 0.34);
	}
}
body.mobile .team-step-in-podium-player {
	padding: 1rem 1.1rem;

	&.not-revealed {
		background: #858585FF;
		border: 3px solid gray;
	}

	p {
		margin-bottom: 0;
	}
}
.checkin-button, .nps-button {
  &.pulsating {
    animation: pulseCheckingAndNpsButtons 2s infinite;

    @keyframes pulseCheckingAndNpsButtons {
      0% {
        box-shadow: 0 0 0 0 rgba(white, 0.7);
      }

      70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(white, 0);
      }
    }
  }
}
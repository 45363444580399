@import "../../../../assets/css/bootstrap/variables";

.list-group-item.current {
	background: lighten($info, 20%) !important;
}

#board-header {
	height: 4.2rem;
	font-size: 0.8rem;
	color: white;
	box-shadow: 0 4px 2px -2px gray;

	.separator {
		display: inline-block;
		border-left: 1px solid $light;
		height: 60%;
		margin: 0 0.70rem 0 0.7rem;
	}

	.badge-team {
		font-size: 100%;
	}

	button {
		border-radius: 4px !important;
	}

	.user {
		&.current {
			background-color: $light;
			border-radius: 4px;
			padding: 4px;

			color: $primary;

			svg {
				color: $primary !important;
			}
		}

		p {
			display: block;
			width: 3.8rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.back-button {
		line-height: 1.3 !important;
	}

	.tournament-button:not(:last-child) {
		margin-right: 0.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
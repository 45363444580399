.conception-startup-modal {
	.game-card {
		display: inline-block;
		min-height: 250px;
		padding-bottom: 20px;
		height: 100%;
	}

	.startup-card, .startup-card-placeholder {
		width: 300px !important;
		height: 100%;
	}

	.startup-card-placeholder{
		min-height: 350px;
	}
}
body.mobile #challenges {
	> .background img {
		opacity: 0.10;
	}

	.challenges-container{
		padding: 0.25rem;
	}
}

body.desktop #challenges {
	.navigation {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0px);

		&.disabled *, {
			color: rgba(255, 255, 255, 0.4);
		}
	}

	.challenges-container{
		padding: 1.5rem;
	}

	.available-for-market-info{
		position: absolute;
	}

	.remaining-questions-info {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	> .background img {
		opacity: 0.25;
	}

}

#challenges {
	z-index: 4;


	& > .background {
		img {
			opacity: 0.25;
			width: 40%;
			min-height: 150px;
			max-height: 80%;
			max-width: 80%;
			object-fit: contain;
		}

		pointer-events: none !important;
	}

	.pulsating {
		border-radius: 4px;
		animation: pulseCheckingAndNpsButtons 2s infinite;

		@keyframes pulseCheckingAndNpsButtons {
			0% {
				box-shadow: 0 0 0 0 rgba(white, 0.7);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba(white, 0);
			}
		}
	}
}
button.animated {
  position: relative;

  &.animation-active {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .80;
    box-shadow: none !important;

    & > .content {
      visibility: hidden;
      opacity: 0;
    }

    &.btn-secondary {
      &:after {
        border-top-color: #000;
      }
    }

    &:not(.btn-secondary) {
      &:after {
        border-top-color: #ffffff;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;

      @keyframes button-loading-spinner {
        from {
          transform: rotate(0turn);
        }

        to {
          transform: rotate(1turn);
        }
      }
    }
  }
}
@import "../../../../../../assets/css/bootstrap/variables";

.smgx-accounting-hiring-details-table {
	td:nth-child(2), td:nth-child(3), td:nth-child(4),
	th:nth-child(2), th:nth-child(3), th:nth-child(4) {
		text-align: center;
	}

	tbody {
		tr {
			td:nth-child(2), td:nth-child(3),
			th:nth-child(2), th:nth-child(3) {
				text-align: center;
			}

			td:nth-child(1) {
				color: $warning;
			}

			td:nth-child(4), td:nth-child(5) {
				color: $danger;
			}

			td:nth-child(6) {
				color: $success;
			}
		}
	}
}

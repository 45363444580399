#board.mobile .hired-cards {

	.hired-service {
		padding: 10px 10px !important;
		border-radius: 4px;
		margin-bottom: 10px;
		background: #303F5C;
		border: 2px solid rgba(255, 255, 255, 0.2);
		min-height: 59px;

		.skill-face {
			width: 50px;
		}
	}

	.btn-show-bonus {
		border: 3px solid #b3b3b3;
	}
}
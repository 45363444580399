@import "../assets/css/bootstrap/variables";

.fullscreen-portal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 999;
	overflow: auto;
	//background: $dark;

	height: 100vh;
	width: 100vw;

	color: white;
}
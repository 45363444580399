@import "../../../assets/css/bootstrap/variables";

body.desktop #nps-form-modal{
	.modal-body > div {
		min-width: 500px;
	}
}

#nps-form-modal {
	.nps-scores {
		list-style: none;
		margin: 0;
		padding: 0;

		&::after {
			content: "";
			clear: both;
		}
	}

	.nps-score-option {
		border: 1px solid #ccc;
		box-sizing: border-box;
		height: 50px;
		position: relative;
		width: 50px;

		input {
			opacity: 0;

			&:focus + label {
				outline: 2px solid $primary;
			}

			&:checked + label {
				background: $primary;
				color: white;
			}

			&:disabled + label {
				background: #e7e7e7;
			}
		}

		label {
			background: #fff no-repeat center center;
			cursor: pointer;
			margin: 0 !important;

			position: absolute;
			right: 1px;
			left: 1px;
			top: 1px;
			bottom: 0;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.submitted-ratings-container {
		max-height: 0;
		transition: all .25s ease-in-out;
		overflow: hidden;

		&.expanded {
			max-height: 400px;
			overflow: auto;
		}
	}

	.score-container {
		.subtitle {
			max-width: 500px;

			p {
				margin-bottom: 0 !important;
			}
		}
	}
}
@import "../../assets/css/bootstrap/variables";


.value-proposition-card {
  transition: all 0.15s ease-in-out;

  &.selectable {
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    .value-content {
      padding: 0.625rem;
    }

    .padding-on-non-clickable {
      padding: 0 !important;
    }
  }

  .box-head {
    font-size: 0.7rem !important;
    max-width: calc(100% - 8px);
  }

  p {
    overflow-wrap: break-word;
  }

  .padding-on-non-clickable {
    padding-right: 0.7rem;
  }

  .value-content {
    padding: 0.6rem;
    padding-right: 0.3rem !important;

    h6 {
      transition: color .1s linear;
    }

    h5 {
      color: $dark;
      font-size: 1.125rem;
      font-weight: 700;
    }
  }
}


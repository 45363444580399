.demo-placeholder {
	color: rgba(0, 0, 0, 0.35);
	padding-bottom: 20px;

	.plus-icon {
		color: rgba(0, 0, 0, 0.2) !important;
	}

	> div {
		margin-left: 7.5px;
		margin-right: 7.5px;
		border: 5px dashed rgba(0, 0, 0, 0.2);
		border-radius: 1.125rem;
		background: rgba(255, 255, 255, 0.2);
	}
}
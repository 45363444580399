.phase-event-separator {
	&.desktop {
		> div {
			min-width: 110px;
			border-left: 4px solid rgba(255, 255, 255, 0.45);

			h4 {
				padding-left: 0.5rem;
				margin-bottom: 0!important;
			}
		}
	}

	&.mobile {
		width: 100%;
		text-align: center;

		> div {
			width: 100%;
			border-bottom: 4px solid rgba(255, 255, 255, 0.45);

			h4 {
				width: 100%;
			}
		}
	}
}
@import "../../../assets/css/bootstrap/variables";
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '~bootstrap/scss/_variables';

#player-login-page {
	.right-card {
		height: 100%;
		//background: #2D3A53
	}

	h1 {
		font-size: 3.2rem !important;
		font-weight: 600 !important;
	}

	.form {
		height: 100%;

		@include media-breakpoint-up(lg) {
			max-width: 500px;
		}

		@include media-breakpoint-down(md) {
			button[type="submit"] {
				width: 100%;
			}
		}
	}

	.logo {
		width: 60%;
		max-width: 500px;
		min-width: 300px;
		object-fit: contain;
	}

	.logo-header {
		width: 280px;
		max-width: 80%;
		max-height: 200px;
		object-fit: contain;
	}

}


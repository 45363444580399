@import "../../../../../assets/css/bootstrap/variables";
@import '../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../../node_modules/bootstrap/scss/variables';

body.desktop .conception-modal {
	.container-custom {
		min-width: 500px;
	}
}

body.mobile .conception-modal {
	padding-bottom: 50px !important;
	background: rgba($primary, 1) !important;

	.btn-close {
		padding: 5px !important;
	}
}


.conception-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	background: rgba($primary, 0.99);
	overflow: auto;
	color: white;

	height: 100vh;
	width: 100vw;

	&.blur > div {
		opacity: 0;
	}

	.animate__animated {
		animation-fill-mode: none !important;
	}


	.btn-navigate:hover {
		color: $info !important;
	}


	.step-buttons {
		&.current button {
			background: $dark;
		}

		> div {
			max-width: 800px;
		}

		.step-button {
			position: relative;

			img {
				width: 50px;
			}

			.step-status-icon {
				position: absolute;
				top: 0;
				left: calc(50% - 40px);
				font-size: 1.5rem;
				pointer-events: none;
			}

			@include media-breakpoint-up(md) {
				width: 900px;

				img {
					width: 60px;
				}

				.step-status-icon {
					left: calc(50% - 55px);
				}
			}

			&.current button {
				background: $dark;
			}

			button {
				width: 100%;

				&:hover {
					background: $info !important;
				}
			}
		}

		.step-divider {
			height: 3px;
			background: $light;
			width: 100px;
			margin-right: 0.5rem;
			margin-left: 0.5rem;
		}

	}

	.tabs-container > div {
		max-width: 800px;
	}

	.btn-info.pulsating {
		animation: pulseBtnInfoConceptionModal 2s infinite;

		@keyframes pulseBtnInfoConceptionModal {
			0% {
				box-shadow: 0 0 0 0 rgba($info, 0.8);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($info, 0);
			}
		}
	}

	.btn-warning.pulsating {
		animation: pulseBtnWarningConceptionModal 2s infinite;

		@keyframes pulseBtnWarningConceptionModal {
			0% {
				box-shadow: 0 0 0 0 rgba($warning, 0.8);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($warning, 0);
			}
		}
	}
}
.card-power-progress-bar {
	width: 75px;
	height: 10px;
	margin-top: 2px;

	.prog-bar{
		background: #DDDDDD;
		width: 100%;
		height: 100%;
		border-color: #000;
		border-width: 0px;
		border-style: solid;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
	}

	.background{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		//background: linear-gradient(-90deg, #FF0000, #EDA63D, #EDA63D, #40C060, #40C060);
		background: linear-gradient(-90deg, #E86559, #F4A347);
		clip-path: inset(0 100% 0 0);
	}
}
@import "../../../../../assets/css/bootstrap/variables";
@import '../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../../node_modules/bootstrap/scss/variables';

#control-panel .player-list {
  button {
	border-radius: 4px !important;
  }

  .filter-by-team-container {
	button {
	  margin-bottom: .25rem;
	}
  }

  .table-players {
	width: 100%;
	max-height: 350px;
	height: 350px;

	.badge {
	  font-size: 100%;
	  width: 20px;
      padding: 0.25rem!important;
	}

	th {
	  padding-top: 0.5rem !important;
	  padding-bottom: 0.5rem !important;

	  &:first-child {
		min-width: 3.8rem;
		width: 1%;
	  }

	  &:nth-child(2) {
		width: 3.8rem;
	  }

	  &:nth-child(3) {
		width: 100.0rem;
	  }
	}

	td {
	  padding-top: 0.25rem !important;
	  padding-bottom: 0.25rem !important;
	  vertical-align: middle;
	}
  }

  .player-status {
	span {
	  content: '';
	  display: inline-block;
	  width: 15px;
	  height: 15px;
	  border-radius: 50%;
	}

	.player-online {
	  background: $success;
	}

	.player-offline {
	  background: $danger;
	}
  }
}

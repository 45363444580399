body.desktop .conception-modal-startup-tab {
	.startup-card {
		width: 400px !important;
	}
}

body.mobile .conception-modal-startup-tab {
	.game-card{
		padding: 0 20px;
	}

	.startup-card {
		width: 300px;
	}
}

.conception-modal-startup-tab {

	.startup-card-content-container {
		overflow: hidden;
	}

	.startup-card {
		min-height: 225px;
		height: 100%;

		.content-scrollable {
			max-height: 100%;
		}
	}
}
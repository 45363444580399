@import "../../../../../../assets/css/bootstrap/variables";


body.mobile .game-events {
	background: #575F70;
	border-radius: 30px;
	z-index: 3;

	// center the event card container
	> div > div {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}

	.btn-scroll-to-top{
		top: calc(100svh - 80px);
	}


	.game-card {
		// shift right box to bottom
		> div {
			flex-direction: column;

			// remove bottom margin between both boxes
			> .box:first-child {
				margin-bottom: 0 !important;
			}
		}
	}
}
@import '../../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import "../../../../../../assets/css/bootstrap/variables";

$br: 1.125rem;

.account-details-modal {
	overflow-x: auto !important;
}

.account-details-modal .modal-dialog {
	height: auto !important;
	background: transparent;


	@media (max-width: 991px) {
		max-width: 100% !important;
	}

	.modal-content {
		width: 100%;
		border-color: $danger;
		border-style: solid;
		border-width: 4px;
		border-radius: $br;

		background: linear-gradient(to bottom left, $dark, $primary);
		background: -webkit-linear-gradient(right, $dark, $primary);
		background: -moz-linear-gradient(right, $dark, $primary);
		background: linear-gradient(to left, $dark, $primary);
	}

	.modal-header {
		margin: -1px -1px 0 -1px;
		border-radius: 0 !important;
		padding: 0 !important;
		font-weight: 800;
		letter-spacing: 0.13rem;
		font-size: 0.9rem;

		.modal-title {
			margin: -1px -1px 0.625rem -1px;
			padding: 0.313rem 1.3rem 0.313rem 1rem;
			border-radius: 0 0 1.125rem 0;
			float: left;
			background: $danger;
			color: $dark;
			font-size: 1.5rem;
			line-height: 1.2;
			font-weight: 800;
			letter-spacing: 0.14rem;
			font-variant: small-caps;
			text-transform: lowercase;
		}

		button {
			padding: 22px 25px 0 20px;
		}
	}

	.hired-cards-container {
		column-gap: 5px;
	}

	.hired-service {
		position: relative;
		width: calc(33% - 5px);

		@include media-breakpoint-down(sm) {
			width: calc(50% - 5px);;
		}

		@media screen and (max-width: 450px) {
			width: 100% !important;
		}

		&, * {
			cursor: default !important;
		}

		.skill-face {
			width: 2.75rem;
		}

		.skill-area {
			display: block;
			max-width: 6rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #212529;

			border-radius: 1.125rem;
			background-color: rgba(179, 179, 179, 0.38);
			padding: 0.1rem 0.3rem;
			margin-right: 0.625rem;
			width: -moz-fit-content;
			width: fit-content;

			font-feature-settings: "smcp";
			font-variant: small-caps;
			text-transform: lowercase;
			font-weight: 600;
			font-size: 0.8rem;
			letter-spacing: 0.063rem;
		}

		.skill-name {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			font-weight: 300;
			font-size: 0.85rem !important;
			margin-bottom: 0 !important;
		}

		.gender-icon {
			border-radius: 50%;
			box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
			position: absolute;
			left: 35px;
			top: 55%;
			width: 17px;
		}
	}
}

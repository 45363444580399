@import "../../../../../../../assets/css/bootstrap/variables";

.question-answer-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: linear-gradient(to bottom right, $dark, $primary);
  overflow: auto;

  .container-custom {
	min-width: 1000px;
  }

  .question-option {
	cursor: pointer;

	&:hover {
	  color: darken($danger, 10%) !important;
	}
  }

  .btn.bg-transparent {
	&:hover {
	  color: darken($danger, 10%) !important;
	}
  }

  .box-answer-result, .btn-reveal-answer, .team-title{
	width: 240px;
  }

  .box-answer-result img {
	  width: 150px;
  }

  .btn-reveal-answer {
	height: 100px;
	font-size: 1.5rem;
	font-weight: bold;
  }

  .question-container {
	width: MAX(50%, 600px) !important;
  }

  .content-container {
	height: 90%;
  }

  .game-card {
	width: 100%;
	min-height: 440px;
	max-height: 600px;
	height: 100%;
	padding-bottom: 2rem;

	.value-content {
	  height: 1%;
	  overflow: hidden;
	}

	.content-scrollable {
	  max-height: 100%;
	}
  }
}
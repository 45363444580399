body.desktop .objective-challenge .game-card {
	.objective-card {
		//   min-height: 15rem !important;

		min-width: 450px;
		max-width: 500px;

		.content-container {
			min-height: 11rem !important;
		}

		.content-scrollable {
			max-height: 350px;
		}
	}
}

body.mobile .objective-challenge .game-card {
	max-width: 500px;
	width: 100%;
}


#control-panel .game-control {
	.nav-link {
		padding: 0.4rem 0.7rem !important;
	}

	.game-phases-container {

		.table-switches {
			margin-bottom: 0 !important;

			td {
				vertical-align: middle;

				&:nth-child(1), {
					white-space: nowrap;
					width: 1%;
					min-width: 50px;
				}

				&:nth-child(2), {
					// white-space: nowrap;
					font-weight: bold;
					// width: 1%;
				}

				&:nth-child(4) {
					width: 220px;
					padding-left: 1.25rem;
					padding-right: 1.25rem;

					.btn:disabled{
						opacity: 0.5;
						cursor: not-allowed;
					}
				}
			}
		}
	}
}



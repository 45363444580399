@import "../../../../../assets/css/bootstrap/variables";

body.desktop .account-summary-card {
	height: 312px;

	&.pulsating{
		animation: pulseAccounting 2s infinite;

		@keyframes pulseAccounting {
			0% {
				box-shadow: 0 0 0 0 rgba($danger, 1);
			}

			70% {
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($danger, 0);
			}
		}
	}

	.box-head {
		padding: 0.313rem .55rem 0.313rem 0.55rem !important;
	}

	.market-value {
		img {
			height: 2.188rem;
		}

		p {
			font-size: 1.5rem;
			font-weight: 400;
			min-height: 2.188rem;
		}
	}

	.acceleration-resume td:first-child {
		font-size: 0.75rem;
		letter-spacing: 0.125rem;
		font-weight: 600;
	}

	table {
		td:nth-child(1) {
			color: $danger;
		}

		td:nth-child(2) {
			color: $light;
		}

		td {
			max-width: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.btn.pulsating {
		animation: pulseBtnAccounting 2s infinite;

		@keyframes pulseBtnAccounting {
			0% {
				box-shadow: 0 0 0 0 rgba($info, 0.7);
			}

			70% {
				box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
			}

			100% {
				box-shadow: 0 0 0 0 rgba($info, 0);
			}
		}
	}

	.bonus-icon {
		margin: 0 5px;
		width: 65px;
	}
}
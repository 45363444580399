@import "../../assets/css/bootstrap/variables";

.startup-card {
  transition: all 0.15s ease-in-out;

  &.selectable {
    &:hover {
      transform: scale(1.05);
    }

    .value-content {
      padding: 0.625rem;
    }

    .padding-on-non-clickable {
      padding: 0 !important;
    }

    .startup-card-content-container {
      cursor: pointer;
    }
  }

  .box-full-head {
    padding: 0.313rem 1.25rem 0.313rem 3.85rem;
    margin: -2px -2px 0 -2px;
    border-radius: 0.625rem 0.625rem 0 0;
    font-weight: 600;
    font-size: 1.125rem;
    width: 101%;

    min-height: 3.9rem;
    max-height: 3.9rem;
    position: absolute;
    z-index: 3;

    > div {
      max-height: 3.9rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      transition: max-height .5s linear;
      overflow: hidden;
      white-space: normal;
      max-height: 100vh !important;
      height: initial !important;
      box-shadow: 0 4px 4px rgba($dark, 0.4);

      > div {
        max-height: 100vh !important;
        display: initial !important;
      }
    }
  }

  .skill-face {
    width: 3rem;
    left: 0;
    top: 0;
    margin: 0.625rem 0 0 0.3rem;
    z-index: 4 !important;
  }

  .startup-card-content-container {
    padding: 0.625rem;
    margin: 3.6rem 0 0 0 !important;

    p {
      font-size: 1rem;
      color: $dark;
    }

    h5 {
      color: $info;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .confirm-overlay {
    border-radius: 8px;
    background: white;
    z-index: -1;
    opacity: 0;
    transition: all 0.15s ease;

    &.show {
      opacity: 1;
      z-index: 2;
    }
  }
}


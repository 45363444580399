@import "../../../../../assets/css/bootstrap/variables";

.startup-profile-summary-card {
  height: 220px;

  .skill-face {
    width: 2.60rem !important;
  }

  .skill-name {
    font-size: 0.9rem !important;
    width: 170px;
  }

  .skill-area {
    padding: 0.15rem 0.6rem !important;
  }

  .startup-name {
    font-size: 1.5rem;
  }

  table tr:not(:last-child) td {
    padding-bottom: .5rem;
  }

  .profile-startup {
    > h3 {
      color: $light;
    }

    .step-result > p {
      padding-left: 2.5rem;
      min-height: 2.188rem;
      margin-top: 0.313rem;
      background-repeat: no-repeat;
      background-size: 1.875rem;
    }

    .bg-logo {
      background-image: url('/assets/img/logos/svg/logo_only.svg');
    }
  }
}
.unpredictability-card {
  .box {
    z-index: 3;
  }

  .unpredictability-icon {
    position: absolute;
    bottom: -15px;
    left: -25px;
    width: 50px !important;
  }

  .consequences-container {
    z-index: 2;
  }

  img {
    width: 25px;
  }
}
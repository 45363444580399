@import "../../../../../../../assets/css/bootstrap/variables";

.ranking-player {
  background: $dark;
  border-style: solid;
  border-width: 0.313rem;
  border-radius: 1.125rem;

  .box-head {
    font-weight: 800;
    letter-spacing: 0.13rem;
    font-size: 1rem;
    float: left;

    margin: -1px -1px 0.625rem -1px;
    padding: 0.313rem 1.25rem 0.313rem 0.938rem;
    border-radius: 0.625rem 0 1.125rem 0;
  }
}
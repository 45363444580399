@import "../../../../../assets/css/bootstrap/variables";


body.desktop .journey-map-summary-card {
  height: 400px;

  .phase-box {
    z-index: 2;
  }

  .phase {
    font-size: 1.3rem;
    background: #00244A;
    min-width: 50px;
    min-height: 50px;
    margin-right: 0.625rem;
    border-radius: 50%;
    border: 0.188rem solid $danger;
    fill: #ced0cf;
    font-weight: bold;
  }

  .step-result, .step-result-placeholder {
    height: 56px;
  }

  .step-result {
    .result-col {
      width: 40%;

      img {
        height: 2.774rem;
      }

      svg {
        font-size: 2.65rem;;
      }
    }

    .summary-col {
      width: 55%;

      img, svg {
        margin-right: .75rem;
        width: 1.2rem;
      }
    }

    p {
      color: $light;
      margin-bottom: .1rem !important;
    }
  }

  .bt-step {
    height: 1.25rem;
    margin: 0 0 1.875rem 0;

    button {
      float: left;
      color: $light;
      font-size: 1.8rem;
      font-weight: 600;

      &:nth-last-child(1) {
        float: right;
      }
    }
  }

  .best-pitch-badge {
    position: absolute;
    top: 30px;
    right: 15px;
    width: 60px;
  }
}

@import "../../../../assets/css/bootstrap/variables";

#confirm-existing-player-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: linear-gradient(to bottom right, $dark, $primary);
  overflow: auto;

  animation-duration: 150ms;

  hr{
    border-color: dimgray!important;
  }

  .anim-step-1 {
    animation-delay: 0.25s;
  }

  .anim-step-2 {
    animation-delay: 1.25s;
  }

  .anim-step-3 {
    animation-delay: 3s;
  }

  .anim-step-4 {
    animation-delay: 4s;
  }

}
@import "../../../../../../assets/css/bootstrap/variables";

.conception-modal-form {
	.modal-dialog {
		height: auto !important;
	}

	.modal-body {
		background-image: linear-gradient(to bottom right, $dark, $light);
	}
}

body.desktop .conception-modal-form{
	.modal-body {
		> div {
			//min-width: 1100px;
		}
	}
}
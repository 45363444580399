@import "../../../assets/css/bootstrap/variables";

$board-card-preview-width: 16rem;
$board-card-preview-min-height: 6.667rem;
$board-card-preview-margin-x: 0.5rem;

.board-card-preview {
  margin-right: $board-card-preview-margin-x;
  margin-left: $board-card-preview-margin-x;

  .box {
    min-height: $board-card-preview-min-height;

    &.double-width {
      width: ($board-card-preview-width * 2) + $board-card-preview-margin-x * 2;
    }

    &:not(.double-width) {
      width: $board-card-preview-width;
    }

    &.not-unlocked {
      border-color: $primary;
      background: rgba(128, 135, 147, 0.6) !important;
    }
  }
}



.conception-modal-founder-tab {
	.founder-card {
		min-height: 225px;
		height: 100%;
		width: 300px !important;

		.content-scrollable {
			max-height: 100%;
		}
	}
}
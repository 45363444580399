@import "../../../../assets/css/bootstrap/variables";

#access-code-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: linear-gradient(to bottom right, $dark, $primary);
	overflow: auto;

	height: 100vh;
	width: 100vw;

	animation-duration: 150ms;

	hr {
		border-color: dimgray !important;
	}
}
.team-control .team-box {
  width: 50%;
  height: 110px;
  color: white;
  display: inline-block;
  transition: all .1s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      z-index: 2;
    }
  }

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .team-startup-name-container {
    height: 50px;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.board-sidebar-content-container {
	.options {
		gap: 10px 15px;
	}

	.sidebar-option {
		width: 175px;
		height: 150px;
		border-radius: 0.25rem;
	}
}
.skill-show {
	position: relative;

	.skill-title {
		line-height: 1.2;
	}

	.skill-area {
		font-size: 0.9rem;
	}

	.description {
		line-height: 1.4;
		font-size: 0.9rem;
	}

	.btn-danger {
		z-index: 99;
	}

	.gender-icon {
		border-radius: 50%;
		box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
		position: absolute;
		left: 43px;
		top: 40px;
		width: 17px;
	}
}
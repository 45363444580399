
body.desktop .conception-modal-value-proposition-tab {
	.value-proposition-card {
		min-height: 225px;
		width: 350px !important;
	}
}

body.mobile .conception-modal-value-proposition-tab {
	.value-proposition-card {
		width: 300px !important;
	}
}

.conception-modal-value-proposition-tab {
	.value-proposition-card {
		height: 100%;


		.value-content {
			overflow: hidden;
		}

		.content-scrollable {
			max-height: 100%;
		}
	}
}
.objective-card {
  .box-head {
    transition: all 3s linear;
    display: block;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    z-index: 3;
    max-height: 35px;

    &:hover {
      transition: all 1s linear;
      white-space: normal;
      max-height: 100vh;
    }
  }
}
@import "../assets/css/bootstrap/variables";
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../node_modules/bootstrap/scss/variables';

#loading-overlay {
	z-index: 999999999999;
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&.fading-out {
		animation-duration: 200ms !important;
		pointer-events: none !important;
	}

	&:not(.fading-out) {
		animation-duration: 50ms !important;
	}

	&.overlay-default {
		color: $dark !important;

		.bg {
			background: $light;
		}
	}

	&.overlay-dark {
		color: $light !important;

		.bg {
			background: $dark;
		}
	}

	.bg {
		z-index: -1;
		transition: background .5s linear;
	}

	.logo-container, .logo-container > svg {
		width: 90% !important;
		max-width: 500px;
		min-width: 200px;
		height: auto !important;
	}

	.text-container {
		animation-delay: 150ms;
		min-height: 40px;

		.text-message {
			margin-top: 13px;
		}
	}

	.loading-circles {
		font-size: 90px;
		animation: dots 1s steps(5, end) infinite;
		line-height: 0.35;

		span {
			animation-name: blink;
			animation-duration: 1.4s;
			animation-iteration-count: infinite;
			animation-fill-mode: both;
			line-height: 0;
			vertical-align: top;

			&:nth-child(2) {
				animation-delay: .2s;
			}

			&:nth-child(3) {
				animation-delay: .4s;
			}

			@keyframes blink {
				0% {
					opacity: .2;
				}
				20% {
					opacity: 1;
				}
				100% {
					opacity: .2;
				}
			}
		}
	}
}
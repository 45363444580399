@import "../../../../../../assets/css/bootstrap/variables";
@import '../../../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../../../node_modules/bootstrap/scss/variables';


.column-view {
	&.style-1 p {
		&:nth-child(1) {
			color: $light;
		}

		&:nth-child(2) {
			color: $warning;
		}
	}

	&.style-2 p {
		&:nth-child(1) {
			color: $info;
		}

		&:nth-child(2) {
			color: $light;
		}
	}
}

.column-view-row {
	display: flex;
	width: 100%;
	gap: 1rem 2rem;
	margin-bottom: 0.15rem;


	> div {
		width: 50%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			margin-bottom: 0.5rem;
		}
	}

	p {
		margin-bottom: 0 !important;
	}
}
@import "../../../../../../../assets/css/bootstrap/variables";

.team-ranking-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-image: linear-gradient(to bottom right, $dark, $primary);
  overflow: auto;

  height: 100vh;
  width: 100vw;

  > div {
    min-width: 850px;
  }

  &:not(.show) {
    display: none;
  }
}
#board.mobile .startup-profile {
	hr{
		border-color: white!important;
	}

	.icon{
		width: 50px;
	}

	.game-card, .startup-card, .value-proposition-card, .founder-card {
		width: 100% !important;

		.content-scrollable {
			overflow: visible;
		}

		.simplebar-content-wrapper{
			overflow: visible!important;
		}
	}


	.founder-card {
		min-height: 200px;
	}

	.value-proposition-card {
		color: black;
	}
}
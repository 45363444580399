#mobile-hired-card-details-modal {
	.game-card {
		width: MIN(600px, 80%);

		.card-summary{
			margin-bottom: 10px;
		}

		.box {
			text-align: center;
			width: 100%;
			min-height: 200px;
		}

		.box-footer {
			display: none !important;
		}
	}
}
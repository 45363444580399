@import "../../../assets/css/bootstrap/variables";

body.desktop #first-access-modal {
	.main-content {
		align-items: center !important;
	}

	.footer-btns {
		margin-top: 1.5rem !important;
	}
}


body.mobile #first-access-modal {
	.main-content {
		padding-top: 4rem !important;
	}

	.footer-btns {
		margin-top: 0.3rem !important;
	}
}


#first-access-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	overflow: auto;
	background: linear-gradient(to bottom right, $dark, lighten($primary, 2%));
	background-size: 400% 400%;
	animation: TutorialModalBg 10s ease infinite;

	hr {
		border-color: dimgray !important;
	}

	.content {
		li {
			margin-bottom: 30px;
			position: relative;
		}

		h5 {
			margin-bottom: 1.5rem;
		}
	}


	@keyframes TutorialModalBg {
		0% {
			background-position: 0 50%;
		}

		50% {
			background-position: 100% 50%;
		}

		100% {
			background-position: 0 50%;
		}
	}
}
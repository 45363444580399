#control-panel-header {
  height: 80px;
  background: #D9D9D9;

  img {
    height: 45px;
  }

  & button {
    border-radius: 4px !important;
  }
}

@import "../../../assets/css/bootstrap/variables";

#lobby {
	&.desktop {
		min-width: 1345px;
	}

	&.mobile {
		min-width: 365px;
	}

	#app-footer {
		button {
			font-size: 80%;
			text-decoration: none;
		}
	}

	background-image: linear-gradient($info, white);
}
@import "../../../assets/css/bootstrap/variables";
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../node_modules/bootstrap/scss/variables';

body.mobile #board {
	padding-bottom: 100px;

	.btn-advance-mobile {
		animation: pulse 5s infinite;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

		@keyframes pulse {
			0% {
				//transform: scale(0.95);
				box-shadow: 0 0 0 0 lighten($success, 25%);
			}

			40% {
				//transform: scale(1);
				box-shadow: 0 0 0 8px transparent;
			}

			100% {
				//transform: scale(0.95);
				box-shadow: 0 0 0 0 transparent;
			}
		}
	}
}

body.desktop #board {
	min-width: 1310px;

	.board {
		height: calc(100% - 140px);
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;

		.preview-board-card {
			background: #D9D9D9;
			cursor: pointer;
		}

		.center-content {
			background: #575F70;
			position: relative;
			border-radius: 30px;
			height: 610px;
			min-width: 500px;

			& > div {
				overflow: hidden;
				min-width: 100%;
				min-height: calc(100% + 8px);
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23394D64' stroke-width='15' stroke-dasharray='20' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
				border-radius: 30px;
				margin: -4px;
			}
		}

		.left-content, .right-content {
			.preview-board-card {
				width: 250px;
				height: 31%;

				@include media-breakpoint-up(xl) {
					width: 300px;
				}

				@include media-breakpoint-up(xxl) {
					width: 350px;
				}
			}
		}
	}

	#app-footer {
		max-width: 255px;
	}
}

#board {
	background: #262F43;

	#app-footer {
		button {
			font-size: 80%;
			text-decoration: none;
		}
	}

	#header {
		width: 100%;
	}

	.badge-team {
		color: white;
	}
}
@import "../../../assets/css/bootstrap/variables";

$game-card-preview-width: 16rem;
$game-card-preview-min-height: 6.667rem;
$game-card-preview-margin-x: 0.5rem;

.game-card {
  position: relative;
  margin-right: $game-card-preview-margin-x;
  margin-left: $game-card-preview-margin-x;

  .box {
    width: $game-card-preview-width;
    min-height: $game-card-preview-min-height;
  }
}



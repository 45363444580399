@import "../../assets/css/bootstrap/variables";

.service-card {
	box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
	transition: all 0.15s ease-in-out;

	&:not(.show-only) {
		&:not(.show-loading) {
			&:not(.not-enough-credits):not(.not-enough-credits):not(.missing-requirements) {
				&:hover {
					transform: scale(1.05);
					transform-origin: 50% 0;
				}

				.box-content {
					cursor: pointer;
				}
			}

			&:hover {
				.hired-amount {
					opacity: 0;
				}
			}
		}

		&.show-loading .loading-overlay {
			opacity: 1 !important;
			z-index: 3 !important;
		}

		&.show-confirm .confirm-overlay {
			opacity: 1 !important;
			z-index: 2 !important;
		}

		&.show-loading, &.show-confirm {
			.confirm-overlay, .loading-overlay {
				padding-bottom: 50px;
			}

			.card-description, .card-summary {
				opacity: 0 !important;
			}
		}

		&.missing-requirements, &.not-enough-credits {
			cursor: not-allowed;
		}

		&.not-enough-credits {
			.box-skill-head, .box-footer {
				background: gray;
				color: black;
			}

			.box-skill-head {
				border: 1px solid black;
			}

			.box-footer .line {
				border-bottom-color: black;
			}

			.skill-face, .power-rating-full {
				-webkit-filter: grayscale(75%);
			}
		}
	}

	&.show-only, &:not(.not-enough-credits) {
		border-color: $danger;

		&.service-type-basic {
			.box-skill-head {
				background: $primary;
			}
		}

		&.service-type-pack {
			.box-skill-head {
				background: linear-gradient(to bottom, #e86559, #ee7453, #f2834d, #f49349, #f4a347) !important;
				border-color: $danger !important;
			}
		}

		&.service-type-power_pack {
			background: linear-gradient(to bottom, #e86559, #ee7453, #f2834d, #f49349, #f4a347) !important;

			.box-skill-head {
				&:not(:hover) {
					background: transparent;
					border: none !important;
				}

				&:hover {
					background: $danger !important;
					border: none !important;
				}
			}
		}

		.box-footer {
			background: $primary;
		}
	}

	.skill-face {
		width: 2.75rem;
		left: 0;
		top: 0;
		margin: 0.4rem 0.625rem 0 0.625rem;
	}

	.box-skill-head {
		width: calc(100% + 2px) !important;
		margin: -1px -1px 0 -1px;
		padding: 0.625rem 0.625rem 0.625rem 4.3rem;
		border-radius: 0.625rem 0.625rem 0 0;
		font-weight: 600;
		font-size: 1rem;
		line-height: 125%;
		color: $light;
		border: 1px solid $primary;

		min-height: 3.75rem;
		max-height: 3.75rem;
		position: absolute;
		z-index: 3;

		> div {
			max-height: 3.75rem;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			word-break: break-word;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:hover {
			//transition: max-height .5s linear;
			overflow: hidden;
			white-space: initial;
			box-shadow: 0 4px 4px rgba($dark, 0.4);

			max-height: 100vh !important;
			height: initial !important;

			> div {
				max-height: 100vh !important;
				display: initial !important;
			}
		}
	}

	.skill-content {
		padding-top: 0.313rem;
		line-height: 1.4;
		margin-top: 3.8rem !important;

		p {
			font-size: 0.95rem !important;
		}
	}

	.skill-area {
		border-radius: 1.125rem;
		background-color: #b3b3b3;
		margin-right: 0.625rem;
		font-size: 0.9rem;
		float: left;
		font-variant: small-caps;
	}

	.box-footer {
		z-index: 2;

		img {
			width: 1.7rem;
		}

		.line {
			flex: 1;
			border-bottom: 1px solid $light;
		}
	}

	.loading-overlay, .confirm-overlay {
		border-radius: 4px;
		display: flex;
		z-index: -1;
		opacity: 0;
		transition: all 0.15s ease;
	}

	.requirements {
		font-size: 0.85rem !important;

		.requirements-title {
			font-weight: bold !important;
			font-variant: small-caps;
			text-transform: lowercase;
		}
	}
}